<template>
	<div>
		<pui-datatable
			:modelName="model"
			:modelColumnDefs="columnDefs"
			:showDeleteBtn="false"
			:externalFilter="externalFilter"
			:navigableDetail="false"
			:showCreateBtn="true"
			:masterDetailColumnVisibles="masterDetailColumnVisibles"
		></pui-datatable>

		<v-dialog v-model="dialog" max-width="90%" max persistent @click:outside="onClickOutside">
			<v-card>
				<v-card-title class="headline">Detail View</v-card-title>
				<v-card-text class="pb-0">
					<!-- <aq-vessel-calls-consumption :id="selectedId" :sourceData="sourceData"></aq-vessel-calls-consumption> -->
					<v-container
						fluid
						class="border-0 rounded"
						style="background-color: #d7ebff !important; padding-top: 0px !important; padding-bottom: 0px !important"
					>
						<v-row no-gutters>
							<v-col v-for="(col, index) in firstRow" :key="index">
								<v-card class="pa-1 my-1 border-0" flat style="background-color: #d7ebff !important">
									<b>{{ $t(col.text) }}</b>
									<span>{{ col.value }}</span>
								</v-card>
							</v-col>
						</v-row>
					</v-container>
					<emissions-dialog
						v-if="selectedId"
						:id="selectedId"
						:sourceData="sourceData"
						@open-fullscreen="openFullScreenMap"
						@close="closeDetail"
					></emissions-dialog>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn class="theme--light primary elevation-0 me-2" text @click="closeDetail">{{ $t('common.close') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="fullmapDialog" max persistent transition="dialog-bottom-transition">
			<aq-vessel-calls-full-map
				v-if="fullmapDialog"
				:data="mapData"
				@close="returnToEmissions"
				style="border-radius: 10px; border: 2px solid white"
			/>
		</v-dialog>
		<v-dialog v-model="vesselCallSearch" persistent max-width="20%" transition="dialog-bottom-transition">
			<v-card class="vessel-call-search-dialog">
				<v-card-title class="headline">{{ $t('grid.aqvesselcalls.newvisit') }}</v-card-title>
				<v-row class="pui-form-layout" v-if="!loadingInserts">
					<v-col cols="12">
						<v-row dense>
							<v-col cols="12">
								<v-text-field
									:id="`emissionscallnum`"
									v-model="emissionscallnum"
									variant="outlined"
									:label="$t('grid.aqvesselcalls.callnum')"
									required
									toplabel
									maxlength="10"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12">
								<v-text-field
									:id="`year`"
									v-model="year"
									:label="$t('grid.aqvesselcalls.year')"
									variant="outlined"
									required
									toplabel
									maxlength="4"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row dense justify="space-between">
							<v-btn
								class="v-btn v-btn--outlined v-btn--text theme--light v-size--default elevation-0 me-2"
								text
								@click="closeNewVisitCall"
								width="100px"
								>{{ $t('common.close') }}</v-btn
							>
							<v-btn class="theme--light primary elevation-0 me-2" text @click="sendDialog" width="100px">{{
								$t('common.send')
							}}</v-btn>
						</v-row>
					</v-col>
				</v-row>
				<v-row v-else>
					<v-col class="text-center">
						<!-- Center the text and progress -->
						<p class="m-2">Cargando... Por favor espere.</p>
						<v-progress-circular
							v-if="loadingInserts"
							:size="30"
							indeterminate
							reactive
							color="primary"
							class="mb-3"
						></v-progress-circular>
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';
import EmissionsDialog from './EmissionsDialog.vue';
//import AqVesselCallsConsumption from './AqVesselCallsConsumption.vue';
import AqVesselCallsFullMap from './AqVesselCallsFullMap.vue';
import { isSuperAdmin, formatTimestamp, insert, search } from '@/api/common';
import axios from 'axios';
import constants from '../../utils/constants';

export default {
	mixins: [PuiGridDetailMixin],
	components: {
		EmissionsDialog,
		AqVesselCallsFullMap
		/* AqVesselCallsConsumption */
	},
	data() {
		return {
			model: 'aqvesselcalls',
			dialog: false,
			fullmapDialog: false,
			selectedId: null,
			loadingInserts: false,
			vesselCallSearch: false,
			sourceData: null,
			emissionscallnum: null,
			year: null,
			mapData: null,
			firstRow: [],
			masterDetailColumnVisibles: {
				aqvesselcallsid: true,
				imo: true,
				name: true,
				vesselmaintype: true,
				callstatus: true,
				totalemissionco2: true,
				totalemissionnox: true,
				ata: true,
				atd: true,
				mpollutant: true
			},
			columnDefs: {
				aqvesselcallsid: {
					// eslint-disable-next-line no-unused-vars
					createdCell: (td, cellData, rowData) => {
						td.id = 'source_' + rowData.aqvesselcallsid;
						td.classList.add('source-cell', 'd-flex', 'justify-center', 'px-2', 'py-1');
						let inputSource = document.createElement('button');
						inputSource.classList.add('source-btn', 'px-3', 'py-1');
						inputSource.addEventListener('click', this.showSource, false);
						inputSource.aqvesselcallsid = cellData;
						inputSource.sourceData = rowData;
						// icon test
						let inputIcon = document.createElement('i');
						inputIcon.classList.add('fa-solid', 'fa-circle-plus', 'fs-regular');
						inputSource.append(inputIcon);
						inputSource.innerHTML = this.$t('grid.aqvesselcalls.aqvesselcallsid') + '<i class="fa fa-plus-circle pl-2"></i>';
						td.innerHTML = '';
						td.appendChild(inputSource);
						return;
					}
				},
				vesselmaintype: {
					render: (data) => {
						return this.formatVesselType(data);
					}
				},
				totalemissionco2: {
					render: (data) => {
						return data.toFixed(3) + 'T';
					}
				},
				totalemissionnox: {
					render: (data) => {
						return data.toFixed(3) + 'T';
					}
				}
			},
			isSuperAdmin: false
		};
	},
	computed: {},
	created() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
	},
	mounted() {
		if (!this.isSuperAdmin) {
			this.externalFilter = {
				groupOp: 'and',
				rules: [
					{ field: 'portauthorityid', op: 'eq', data: this.userProperties.organizationid },
					{ field: 'anch_time', op: 'gt', data: '0' }
				],
				groups: []
			};
		} else {
			this.externalFilter = {
				groupOp: 'or',
				rules: [
					{ field: 'anch_time', op: 'gt', data: '0' },
					{ field: 'man_time', op: 'gt', data: '0' },
					{ field: 'hot_time', op: 'gt', data: '0' }
				],
				groups: []
			};
		}
		window.addEventListener('keydown', this.keyDown);
		// Esta funcion retiene en boton de crear pero reemplaza sus eventos para que no haga funciones de framework
		this.$nextTick(() => {
			const createBtn = document.getElementById('gridbtn-create-aqvesselcalls');

			if (createBtn) {
				console.log('Create button found!');
				const newBtn = createBtn.cloneNode(true);
				createBtn.parentNode.replaceChild(newBtn, createBtn);
				newBtn.addEventListener('click', (event) => {
					event.preventDefault();
					event.stopImmediatePropagation();
					console.log('Create button clicked, all default behavior removed!');
					this.vesselCallSearch = true;
				});
				// De momento esconder para los puertos que no son barcelona
				if (this.$store.getters.getUser != 'barcelona') {
					newBtn.style.display = 'none';
				}
			}
		});
	},
	methods: {
		formatVesselType(vesselType) {
			let splitArr = vesselType.split('_');
			let capArr = [];
			splitArr.forEach((word) => {
				capArr.push(this.capitalize(word));
			});
			//console.log('vessel type', capArr.join(' '));

			return capArr.join(' ');
		},
		showSource(evt) {
			this.selectedId = evt.currentTarget.aqvesselcallsid;
			this.sourceData = evt.currentTarget.sourceData;
			this.dialog = true;
			let date = new Date(this.sourceData.ata);
			console.log(
				'Debug show source: ',
				evt.currentTarget.aqvesselcallsid,
				this.sourceData,
				date,
				date.valueOf(),
				formatTimestamp(date.valueOf(), this.userProperties)
			);
			this.firstRow = [
				{ text: this.$t('grid.aqvesselcalls.name'), value: this.sourceData.name },
				{ text: this.$t('grid.aqvesselcalls.callnum'), value: this.sourceData.callnum },
				{ text: this.$t('grid.aqvesselcalls.start'), value: formatTimestamp(new Date(this.sourceData.atd).valueOf(), this.userProperties) },
				{ text: this.$t('grid.aqvesselcalls.end'), value: formatTimestamp(new Date(this.sourceData.ata).valueOf(), this.userProperties) },
				{ text: this.$t('grid.aqvesselcalls.vesselmaintype'), value: this.formatVesselType(this.sourceData.vesselmaintype) }
			];
		},
		closeDetail() {
			this.selectedId = null;
			this.sourceData = null;
			this.firstRow = [];
			this.dialog = false;
		},
		keyDown(event) {
			if (event.key === 'Escape' || event.keyCode === 27) {
				if (this.dialog) {
					this.closeDetail();
				}
			}
		},
		capitalize(string) {
			//console.log('capitalize ', string);
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		onClickOutside() {
			//console.log("Nada")
		},
		openFullScreenMap(data) {
			this.mapData = data;
			this.dialog = false;
			console.log('This is map: ', this.mapData);
			this.fullmapDialog = true;
		},
		returnToEmissions() {
			this.fullmapDialog = false;
			this.dialog = true;
		},
		async sendDialog() {
			this.loadingInserts = true;
			try {
				const token = 'pob';
				const endpoint = constants.LOCAL_ORCH + 'VesselCallEmissions';

				const response = await axios.post(
					endpoint,
					{
						callid: this.emissionscallnum,
						year: this.year
					},
					{
						headers: {
							Authorization: `Bearer ${token}`,
							'Content-Type': 'application/json'
						}
					}
				);

				let formattedData = this.formatData(response.data);
				console.log('Request successful:', formattedData);
				let vesselId = await insert(formattedData.vessel, '/aqvessels');
				formattedData.vesselenginedata.aqvesselsid = vesselId.data.aqvesselsid;
				formattedData.vesselenginedata.enginetier = String(formattedData.vesselenginedata.enginetier);
				await insert(formattedData.vesselenginedata, '/aqvesselenginesdata');
				formattedData.vesselcall.aqvesselsid = vesselId.data.aqvesselsid;
				formattedData.vesselcall.portauthorityid = this.userProperties.organizationid;
				let vesselcallid = await insert(formattedData.vesselcall, '/aqvesselcalls');
				formattedData.docking.aqvesselcallsid = vesselcallid.data.aqvesselcallsid;
				await insert(formattedData.docking, '/aqvesselcalldocking');
				formattedData.vesselcallconsumptions[0].aqvesselcallsid = vesselcallid.data.aqvesselcallsid;
				let vesselcallconsumptionsid = await insert(formattedData.vesselcallconsumptions[0], '/aqvesselcallconsumptions');

				for (const pollutant of formattedData.vesselcallemissionspollutants) {
					pollutant.aqvesselcallconsumptionsid = vesselcallconsumptionsid.data.aqvesselcallconsumptionsid;
					pollutant.aqversionemissionfactorid = 1; // Cuidao, hay que importarlo de aq_version_emission_factor
					pollutant.mpollutant = pollutant.pollutant;

					await insert(pollutant, '/aqvesselcallemissionspollutantversion');
				}

				for (const routeSection of formattedData.routesections) {
					routeSection.aqvesselcallsid = vesselcallid.data.aqvesselcallsid;
					routeSection.aqphasesid = routeSection.phase;
					let routeSectionid = await insert(routeSection, '/aqroutesection');
					console.log('Route Section ID: ', routeSection.id);
					console.log('Inserted Route Section ID: ', routeSectionid.data.aqroutesectionid);
					for (const routeSectionLocation of formattedData.routesectionlocations) {
						if (routeSectionLocation.routesectionid == routeSection.id) {
							routeSectionLocation.aqroutesectionid = routeSectionid.data.aqroutesectionid;
							await insert(routeSectionLocation, '/aqroutesectionlocation');
						}
					}

					for (const routeSectionPollutant of formattedData.routesectionemissionspollutants) {
						if (routeSectionPollutant.routesectionsid == routeSection.id) {
							routeSectionPollutant.aqpollutantsid = routeSectionPollutant.pollutant;

							routeSectionPollutant.aqroutesectionid = routeSectionid.data.aqroutesectionid;
							await insert(routeSectionPollutant, '/aqroutesectionemissionspollutant');
						}
					}
				}

				this.loadingInserts = false;
				this.vesselCallSearch = false;
				this.selectedId = vesselcallid.data.aqvesselcallsid;

				const filters = {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'id', op: 'eq', data: this.selectedId }]
				};
				const body = {
					model: 'aqvesselcalls',
					filter: filters
				};

				const insertVC = await search(body);
				console.log('I found:', insertVC.data.data[0]);
				this.sourceData = insertVC.data.data[0];
				this.firstRow = [
					{ text: this.$t('grid.aqvesselcalls.name'), value: this.sourceData.name },
					{ text: this.$t('grid.aqvesselcalls.callnum'), value: this.sourceData.callnum },
					{
						text: this.$t('grid.aqvesselcalls.start'),
						value: formatTimestamp(new Date(this.sourceData.atd).valueOf(), this.userProperties)
					},
					{ text: this.$t('grid.aqvesselcalls.end'), value: formatTimestamp(new Date(this.sourceData.ata).valueOf(), this.userProperties) },
					{ text: this.$t('grid.aqvesselcalls.vesselmaintype'), value: this.formatVesselType(this.sourceData.vesselmaintype) }
				];
				this.dialog = true;
			} catch (error) {
				console.error('Error in the process:', error);
			}
		},
		formatData(obj) {
			if (typeof obj !== 'object' || obj === null) {
				return obj;
			}

			const newObj = Array.isArray(obj) ? [] : {};

			Object.keys(obj).forEach((key) => {
				const newKey = key.replace(/_/g, '').toLowerCase();

				newObj[newKey] = this.formatData(obj[key]);
			});

			return newObj;
		},
		closeNewVisitCall() {
			this.vesselCallSearch = false;
		}
	},
	beforeDestroy() {
		window.removeEventListener('keydown', this.keyDown);
	}
};
</script>

<style lang="postcss">
td.source-cell {
	button.source-btn {
		color: #fff !important;
		background-color: var(--azul-primary-100) !important;
		border-radius: 4px !important;
	}
	button.source-btn.disabled {
		color: #fff !important;
		background-color: var(--gris-300) !important;
		border-radius: 4px !important;
	}
}
.vessel-call-search-dialog {
	overflow: hidden;
}
</style>
